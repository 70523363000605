class FormHandler {
    
    constructor(form, method, url, redirectUrl) {
        this.form = form;
        this.method = method;
        this.url = url;
        this.redirectUrl = redirectUrl;
    }

    submit() {
        switch (this.method) {
            case 'POST':
                this.post();
                break;
            default:
                console.warn('Invalid method ' + this.method);
                break;
        }
    }

    post() {
        let event;
        let eventDetail = { id: this.form.id };
        axios.post(this.url, new FormData(this.form)) // https://gomakethings.com/serializing-form-data-with-the-vanilla-js-formdata-object/
        .then(response => {
            eventDetail.response = response.data;
            eventDetail.redirectUrl = this.redirectUrl;
            event = new CustomEvent('successfulFormSubmission', { detail: eventDetail });
        }).catch((error)=> {
            eventDetail.response = error.response.data;
            event = new CustomEvent('badFormSubmission', { detail: eventDetail });
        }).finally( () => {
            window.dispatchEvent(event);
        });
    }

    /*
    function GenerateRandomNumber() {
        var timestamp = new Date().getUTCMilliseconds();
        return Math.floor(Math.random()) + 1000 + timestamp;
    }
    */
}

export { FormHandler }
