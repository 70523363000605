// Bad form submission event listener
export const BadFormSubmissionListener = function() {
    window.addEventListener('badFormSubmission', (event) => {
        const formSubmitStateEvent = new CustomEvent('formSubmitFinished', { detail: { formId: event.detail.id } });
        window.dispatchEvent(formSubmitStateEvent);
        const errors = event.detail.response.errors;
        let firstError;
        Object.keys(errors).forEach(function (inputName, index) {
            const input = document.querySelector('input[name='+inputName+']');
            if(index === 0) {
                firstError = input;
            }
            const message = errors[inputName].join(' '); // <- Message is an array!
            const formGroup = input.parentNode;
            const div = document.createElement('div');
            input.classList.add('is-invalid');
            div.classList.add('invalid-feedback');
            div.innerText = message;
            formGroup.append(div);
        });
        firstError.parentNode.parentNode.scrollIntoView({behavior: 'smooth'});
    });
}
