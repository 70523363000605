// Close button
export const CloseBtn = function(cssClassName, cssHideClassName) {
    
    const cssClass = cssClassName;

    const cssHideClass = cssHideClassName;

    init();

    function init() {
        if( hasElement() ) {
            const elements = getAllElements();
            elements.forEach((element) => {
                addListener(element);
            });
        }
    }

    function hasElement() {
        const elements = getAllElements();
        return (elements && elements.length > 0) ? true : false;
    }

    function getAllElements() {
        return document.querySelectorAll('.'+cssClass);
    }

    function hasTarget(element) {
        const targets = getElementTargets(element);
        return (targets) ? true : false;
    }

    function hasMultipleTargets(targets) {
        return (targets.indexOf(',') > -1) ? true : false;
    }

    function splitTargets(targets) {
        return targets.split(',');
    }

    function getElementTargets(element) {
        return element.getAttribute('data-dismiss');
    }

    function addListener(element) {
        let success = false;
        if( hasTarget(element) ) {
            const targets = getElementTargets(element);
            let dismissList = [];
            if( ! hasMultipleTargets(targets) ) {
                dismissList.push(targets);
            } else {
                dismissList = splitTargets(targets);
            }
            element.addEventListener('click', (event) => {
                dismissList.forEach((id) => {
                    let dismissElement = document.getElementById(id);
                    if( dismissElement ) {
                        dismissElement.classList.add(cssHideClass);
                        const closeEvent = new CustomEvent('CloseBtnClicked', { detail: id });
                        window.dispatchEvent(closeEvent);
                        if(!success) {
                            success = true;
                        }
                    }

                });
            });
        }
        return success;
    }


}
