export const ModuleConfig = {
    GoogleMap: {
        placeholderId: 'map',
        loaderConfig: {
            apiKey: "AIzaSyBwx4kPNws-SmENDfgqRqNhLrBhhSFiHqY",
            version: "weekly",
            libraries: ["places"]
        },
        options: {
            center: {
                lat: 49.1399200,
                lng: 9.2376700
            },
            zoom: 12,
            styles: [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "featureType": "administrative.country",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ff3152"
                  }
                ]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "administrative.locality",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "administrative.neighborhood",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ff3152"
                  }
                ]
              },
              {
                "featureType": "administrative.province",
                "elementType": "labels.text",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "poi.medical",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "visibility": "on"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ff3152"
                  },
                  {
                    "visibility": "on"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "transit.station.bus",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ff3152"
                  }
                ]
              },
              {
                "featureType": "transit.station.bus",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#ff3152"
                  }
                ]
              },
              {
                "featureType": "transit.station.rail",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#ff3152"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#83d2f1"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#83d2f1"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#6e7b8d"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#ffffff"
                  },
                  {
                    "weight": 2
                  }
                ]
              }
            ]
        },
        markers: [
            {
                position: {
                    lat: 49.1399200,
                    lng: 9.2376700
                }
            }
        ]
    },
    ModalGoogleMap: {
        placeholderId: 'modal-map',
    },
    ChangeOnHover: ['white-logo-in-header','red-logo-in-header','hide'],
    CookieNoticeBtn: ['btn-cookie-notice-accept','hide'],
    CloseBtn: ['custom-close-btn','custom-hide'],
    CollapseBtnListener: ['#navbarCollapse', 'main-nav', 'toggled'],
    Timeline: {
        selector: '.timeline',
        options: {
            verticalStartPosition: 'right',
            verticalTrigger: '150px'
        }
    },
    BsBugFix: {
        'collapse': '#accordion'
    },
    Forms: [
        {
            'id': 'contact-form',
            'method': 'POST',
            'url': '/contact-form',
            'redirectUrl': '/danke-fuhr-ihr-interesse',
        }
    ],
    Loader: 'loader',
    Swiper: ['.swiper', {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        loop: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        // Navigation arrows
        navigation: false,

        // And if we need scrollbar
        scrollbar: false
    }],
    SectionInViewport: {
        path: '/',
        sections: ['uber-mich', 'fur-sie', 'dienstleistungen', 'kontakt']
    }
};
