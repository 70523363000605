import { ElementStyleManipulator } from '../actions';

/**
 * Bootstrap Collapse button event listener
 * @info Sadly Bs uses JQuery Events so vanilla js can not listen them :(
 * @see https://getbootstrap.com/docs/3.4/javascript/#collapse
 */
export const CollapseBtnListener = function(myCollapsible, target, className) {
    const MobileNav = new ElementStyleManipulator(document.getElementById(target), className);
    const ContactBar = new ElementStyleManipulator(document.getElementById('contact-bar'));
    //const Main = new ElementStyleManipulator(document.querySelector('main'));
    const WhiteNavLogo = new ElementStyleManipulator(document.getElementById('white-logo-in-header'));
    const GrayNavLogo = new ElementStyleManipulator(document.getElementById('gray-logo-in-header'));
    $(myCollapsible).on('show.bs.collapse', function () {
        MobileNav.show();
        WhiteNavLogo.toggle('hide');
        GrayNavLogo.toggle('hide');
    });
    $(myCollapsible).on('shown.bs.collapse', function () {
        ContactBar.toggle('hide');
        //Main.toggle('hide');
    });
    $(myCollapsible).on('hide.bs.collapse', function () {
        MobileNav.hide();
        WhiteNavLogo.toggle('hide');
        GrayNavLogo.toggle('hide');
    });
    $(myCollapsible).on('hidden.bs.collapse', function () {
        ContactBar.toggle('hide');
        //Main.toggle('hide');
    });
}
