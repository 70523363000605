// Loader
export const Loader = function(id) {
    
    const loader = document.getElementById(id);

    window.addEventListener('formSubmitStarted', toggle);
    window.addEventListener('formSubmitFinished', toggle);

    function toggle() {
        if(loader) {
            loader.classList.toggle('active');
        }
    }

}
