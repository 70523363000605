// Logo :hover
export const ChangeOnHover = function(defaultSelector, hoverSelector, className) {
    const defaultElement = document.getElementById(defaultSelector);
    const hoverElement = document.getElementById(hoverSelector);

    defaultElement.addEventListener('mouseenter', toggle);
    defaultElement.addEventListener('mouseleave', toggle);

    function toggle() {
        if(defaultElement && hoverElement) {
            if(defaultElement.classList.contains(className)) {
                defaultElement.classList.remove(className);
                hoverElement.classList.add(className);
            } else {
                defaultElement.classList.add(className);
                hoverElement.classList.remove(className);
            }
        }
    }

}
