export const FormInputRemoveInvalidState = function(input) {
    if( input.classList.contains('is-invalid') ) {
        input.classList.remove('is-invalid');
        const formGroup = input.parentNode;
        const invalidFeedback = formGroup.querySelector('.invalid-feedback');
        if( formGroup && invalidFeedback) {
            formGroup.removeChild(invalidFeedback);
        }
    }
}
