import { FormHandler } from '../actions';

// Form Submit listener
export const FormSubmitListener = function(id, method, url, redirectUrl) {
    var form = document.getElementById(id);
    if(form) {
        form.addEventListener("submit", (event) => {
            event.preventDefault();
            const formSubmitStateEvent = new CustomEvent('formSubmitStarted', { detail: { formId: id } });
            window.dispatchEvent(formSubmitStateEvent);
            const handler = new FormHandler(event.target, method, url, redirectUrl);
            handler.submit();
        });
    }
}

