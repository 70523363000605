import * as Modules from './modules';

class ModuleLoader {
    
    init(config) {
        const CookieNotice = Modules.CookieNotice();
        const CookieNoticeBtn = Modules.CloseBtn(...config.CookieNoticeBtn);
        const NavLinkClickListener = Modules.NavLinkClickListener();
        const ChangeOnHover = Modules.ChangeOnHover(...config.ChangeOnHover);
        const CloseBtn = Modules.CloseBtn(...config.CloseBtn);
        const CloseBtnListener = Modules.CloseBtnListener();
        const CollapseBtnListener = Modules.CollapseBtnListener(...config.CollapseBtnListener);
        const Timeline = Modules.Timeline(document.querySelectorAll(config.Timeline.selector), config.Timeline.options);
        // const BsBugFix = Modules.BsBugFix(config.BsBugFix.collapse);
        const Loader = Modules.Loader(config.Loader);
        config.Forms.forEach( function(form, index) {
            Modules.FormSubmitListener(form.id, form.method, form.url, form.redirectUrl);
            Modules.FormInputChangeListener(form.id);
        });
        const FormSubmitStartedListener = Modules.FormSubmitStartedListener();
        const SuccessfulFormSubmissionListener = Modules.SuccessfulFormSubmissionListener();
        const BadFormSubmissionListener = Modules.BadFormSubmissionListener();
        const SwiperLoader = Modules.SwiperLoader(...config.Swiper);
        const Gmap = Modules.GoogleMap(config.GoogleMap.placeholderId, config.GoogleMap.loaderConfig, config.GoogleMap.options, config.GoogleMap.markers);
        const TopNotice = Modules.TopNotice();
        const SectionInViewport = Modules.SectionInViewport(config.SectionInViewport.path, config.SectionInViewport.sections);
        const WindowScrollListener = Modules.WindowScrollListener();
        const ModalGmap = Modules.GoogleMap(config.ModalGoogleMap.placeholderId, config.GoogleMap.loaderConfig, config.GoogleMap.options, config.GoogleMap.markers);
        const ContactBarMapPinClickListener = Modules.ContactBarMapPinClickListener();
    }
    
}

export { ModuleLoader }
