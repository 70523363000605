// Window scroll listener
export const WindowScrollListener = function() {
    window.addEventListener('scroll', function() {
        const nav = document.getElementById('main-nav');
        const navParent = nav.parentNode;
        const navbarNoVerticalPaddingCssClass = 'no-vertical-padding';
        const cssScrollCssClass = 'nav-scroll-shadow';
        const navLinks = document.querySelectorAll('.navbar-nav .nav-link');
        const navLinksRemoveShadowCssClass = 'no-text-shadow';
        const navCta = document.querySelector('.nav-link.nav-link-btn-primary');
        const navCtaRemoveShadowCssClass = 'no-shadow';
        const logos = document.querySelectorAll('.logo-in-header');
        if (window.scrollY > 50) {
            nav.classList.add(navbarNoVerticalPaddingCssClass);
            navParent.classList.add(cssScrollCssClass);
            navLinks.forEach(function(link){
                link.classList.add(navLinksRemoveShadowCssClass);
            });
            navCta.classList.add(navCtaRemoveShadowCssClass);
            logos.forEach(function(logo){
                logo.classList.add('size-small');
            });
        } else {
            nav.classList.remove(navbarNoVerticalPaddingCssClass);
            navParent.classList.remove(cssScrollCssClass);
            navLinks.forEach(function(link){
                link.classList.remove(navLinksRemoveShadowCssClass);
            });
            navCta.classList.remove(navCtaRemoveShadowCssClass);
            logos.forEach(function(logo){
                logo.classList.remove('size-small');
            });
        }
    });
}
