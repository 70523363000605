/**
 * Bootstrap bugfix for long content
 * @info https://getbootstrap.com/docs/4.0/components/collapse/
 */
export const BsCollapseDynamicContentFix = function(selector) {
    $('document').ready(function() {
        $(selector).on('show.bs.collapse hide.bs.collapse', function() {
            var panel = $(this).find('.show').parent().parent();
            if(panel.offset()) {
                $('html,body').animate({
                    scrollTop: panel.offset().top
                }, 500);
            }
        });
    });
}
