// Successful Form submission event listener
export const SuccessfulFormSubmissionListener = function() {
    window.addEventListener('successfulFormSubmission', (event) => {
        const formSubmitStateEvent = new CustomEvent('formSubmitFinished', { detail: { formId: event.detail.id } });
        window.dispatchEvent(formSubmitStateEvent);
        const form = document.getElementById(event.detail.id);
        form.reset();
        window.location.href = event.detail.redirectUrl;
    });
}
