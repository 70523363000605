export const NavLinkClickListener = function() {
    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach((link) => {
        link.addEventListener('click', (event) => {
            const url = new URL(event.target.href);
            const path = url.pathname;
            const hash = url.hash;
            if(window.location.pathname === '/' && path === '/' && hash && hash.length > 1) {
                event.preventDefault();
                const elementTopPos = document.querySelector(hash).offsetTop;
                window.scrollTo({
                  top: elementTopPos - 100,
                  behavior: "smooth"
                });
                if(window.innerWidth < 762) {
                    $('.navbar-collapse').collapse('hide');
                }
            }
        });
    })
}
