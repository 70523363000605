// Google map
import { Loader } from '@googlemaps/js-api-loader';

export const GoogleMap = function(placeholderId, loaderConfig, options, markers) {

    const loader = new Loader(loaderConfig);
    const mapOptions = options;
    const mapMarkers = markers;
    const mapMarkerImage = 'images/map/marker.png';

    const mapPlaceholder = document.getElementById(placeholderId);

    if(mapPlaceholder) {

        loader
        .load()
        .then((google) => {
            const map = new google.maps.Map(mapPlaceholder, mapOptions);
            if(mapMarkers && mapMarkers.length > -1) {
                mapMarkers.forEach((marker) => {
                    new google.maps.Marker({
                        position: marker.position,
                        map: map,
                        icon: mapMarkerImage,
                    });
                });
            }
        })
        .catch(e => {
            console.log(e);
        });

    }


}



