import { FormInputRemoveInvalidState } from '../actions';

// Form input change event listener
export const FormInputChangeListener = function(id) {
    
    const form = document.getElementById(id);

    if(form) {
        const formInputs = form.querySelectorAll("[name]");
        if(formInputs) {
            formInputs.forEach((input) => {
                input.addEventListener('change', removeInvalidState.bind(this, input));
            });

            function removeInvalidState(input) {
                console.log('Input ('+input.name+') change event dispatched');
                FormInputRemoveInvalidState(input);
            }
        }
    }
}
