class ElementStyleManipulator {
    
    constructor(element, className) {
        this.element = element;
        this.className = className;
    }

    show() {
        try {
            this.element.classList.add(this.className);
        } catch(e) {
            console.warn(e);
        }
    }

    hide() {
        try {
            this.element.classList.remove(this.className);
        } catch(e) {
            console.warn(e);
        }
    }

    toggle(className) {
        try {
            this.element.classList.toggle(className);
        } catch(e) {
            console.warn(e);
        }
    }
}

export { ElementStyleManipulator }
