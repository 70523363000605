import 'waypoints/lib/noframework.waypoints.min.js';

// Section in viewport
export const SectionInViewport  = function(path, sections) {
    const navLinks = document.querySelectorAll('#main-nav .nav-link');
    if(window.location.pathname == path) {
        sections.forEach(function(section){
            new Waypoint({
                element: document.getElementById(section),
                handler: function(direction) {
                    if( direction === 'down') {
                        navLinks.forEach(function(a){
                            const aHref = a.getAttribute("href").replace(/[^a-zA-Z0-9-]/g, '');
                            if( section == aHref ) {
                                a.classList.add('in-view');
                            } else {
                                a.classList.remove('in-view');
                            }
                        });
                    }
                }
            });
            new Waypoint({
                element: document.getElementById(section),
                handler: function(direction) {
                    if( direction === 'up') {
                        navLinks.forEach(function(a){
                            const aHref = a.getAttribute("href").replace(/[^a-zA-Z0-9-]/g, '');
                            if( section == aHref ) {
                                a.classList.add('in-view');
                            } else {
                                a.classList.remove('in-view');
                            }
                        });
                    }
                },
                offset: function () {
                    return this.context.innerHeight() - this.adapter.outerHeight() - 100;
                }
            });
        });
    }
}
