export const ContactBarMapPinClickListener = function() {
    const iconLink = document.querySelector('.contact-bar__element--location');
    if(iconLink) {
        iconLink.addEventListener('click', (event) => {
            if(window.innerWidth > 992) {
                event.preventDefault();
                $('#gmapModal').modal();
            }
        });
    }
}
