import Cookies from 'js-cookie'

export const TopNoticeState = function() {
    const cookieName = 'notice-shown';
    const cssClassName = 'top-notice-visible';
    const notice = document.getElementById('notice');
    let shown = Cookies.get(cookieName);
    if( ! shown ) {
        document.body.classList.add(cssClassName);
        notice.classList.add('show');
        Cookies.set(cookieName, 'true');
    } else {
        if( document.body.classList.contains(cssClassName) ) {
            document.body.classList.remove(cssClassName);
        }
        if( notice.classList.contains('show') ) {
            notice.classList.remove('show');
        }
        if( ! notice.classList.contains('hide') ) {
            notice.classList.add('hide');
        }
    } 
}
