import { FormInputRemoveInvalidState } from '../actions';

// Form Submit started listener
export const FormSubmitStartedListener = function() {
    window.addEventListener('formSubmitStarted', (e) => {
        const form = document.getElementById(e.detail.formId);
        const formInputs = form.querySelectorAll("[name]");
        formInputs.forEach( (input) => {
            FormInputRemoveInvalidState(input);
        });
    });
}

