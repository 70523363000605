import Cookies from 'js-cookie'

export const CookieNoticeState = function() {
    const cookieName = 'cookie-notice-accepted';
    const notice = document.getElementById('cookie-notice');
    let accepted = Cookies.get(cookieName);
    if( ! accepted ) {
        notice.classList.add('show');
    } else {
        if( notice.classList.contains('show') ) {
            notice.classList.remove('show');
        }
        if( ! notice.classList.contains('hide') ) {
            notice.classList.add('hide');
        }
    }
    window.addEventListener('CloseBtnClicked', (event) => {
        if(event.detail == 'cookie-notice') {
            notice.classList.remove('show');
            Cookies.set(cookieName, 'true', { expires: 365 });
        }
    });
}
